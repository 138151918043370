import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

class Accordion01 extends Component {
  render() {
    return (
      <Accordion className="accodion-style--1" preExpanded={"0"}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>What is VLSI?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            The design and production of integrated circuits (ICs) with tens of
            thousands, millions, or even billions of transistors on a single
            chip is known as very large scale integration, or VLSI. VLSI has the
            ability to create extremely sophisticated and potent electronic
            systems and it has revolutionized the electronics sector.{" "}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              What is the scope of learning VLSI?{" "}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            VLSI opens up a wide range of opportunities in the fields of
            semiconductor design and electronics. Learning VLSI offers you a
            wide range of opportunities for employment in the fields of
            research, integrated circuit design, fabrication, and electronic
            system design. It opens doors to a fulfilling career in the
            electronics industry and provides options for specialization in a
            variety of fields.
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Which are the leading VLSI companies where can I start my career?{" "}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            Here are a few well-known VLSI companies in India:
            <ul>
              <li>Intel India </li>
              <li>Qualcomm India </li>
              <li>Samsung Semiconductor India </li>
              <li>Texas Instruments India </li>
              <li>NVIDIA Graphics Pvt. Ltd. </li>
              <li>Broadcom India Pvt. Ltd. </li>
              <li>Synopsys India </li>
              <li>Cadence Design Systems India Pvt. Ltd. </li>
              <li>ARM Embedded Technologies Pvt. Ltd. </li>
              <li>MediaTek India Technology Pvt. Ltd. </li>
              <li>Advanced Micro Devices (AMD) India </li>
              <li>Infineon Technologies India Pvt. Ltd. </li>
              <li>Cypress Semiconductor Technology India Pvt. Ltd. </li>
              <li>Analog Devices India Pvt. Ltd. </li>
              <li>Rambus Chip Technologies India Pvt. Ltd. </li>
            </ul>
            There is also a thriving startup environment in India focused on
            semiconductor design, embedded systems, and VLSI-related
            technologies.
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Will I get a successful career after completing VLSI at IPCS
              Global?{" "}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            At IPCS Global, we prepare you for the competent and ever-growing
            digital marketing sector. Our training center maintain a
            consistently updated program syllabus taught by well-qualified
            teachers who are also industry experts. We guarantee more than 90%
            of placement opportunities upon completion of the course. The
            success of thousands of students who have been placed in reputed
            companies is a testament to the excellence that we offer.
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              What salary can a beginner expect from the industry ?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            On average, a beginner VLSI Engineer in India can expect a range of
            salary between ₹3 lakh and ₹6 lakh annually.
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}

export default Accordion01;
