import React, { Component } from "react";
import ModalForm from "./ModalForm";

class ContactFour extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <br />
              <div className="section-title text-left mb--40">
                <h2 className="title" style={{color: 'white'}}>Contact Us.</h2>
              </div>
              {/* <div className="form-wrapper"> */}
                <ModalForm />
              {/* </div> */}
            </div>
        
      </div>
    );
  }
}
export default ContactFour;
