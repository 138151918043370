import React, { Component , Fragment } from "react";
import Slider from "react-slick";
import ScrollToTop from 'react-scroll-up';
import { FiCast, FiChevronUp, FiLayers, FiUsers } from "react-icons/fi";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import Header from "../component/header/HeaderFive";
import Testimonial from "../elements/Testimonial";
import PortfolioList from "../elements/portfolio/PortfolioList";
import BlogContent from "../elements/blog/BlogContent";
import Brand from "../elements/Brand";
import CallAction from "../elements/callaction/CallAction";
import TabOne from "../elements/tab/TabOne";
import Helmet from "../component/common/Helmet";
import BrandTwo from "../elements/BrandTwo";
import ContactTwo from "../elements/contact/ContactTwo";
import Footer from "../component/footer/Footer";
import Modal from 'react-modal'
import ContactForm from "../elements/contact/ContactForm";
import ContactFour from "../elements/contact/ContactFour";


const ServiceListOne = [
    {
      icon: <FiCast />,
      title: "VLSI Design",
      description:
        "VLSI Training programs focus on teaching the principles and techniques of digital IC design. ",
    },
    {
      icon: <FiLayers />,
      title: "VLSI Training",
      description:
        "VLSI training covers the fundamentals of IC designs & verification techniques.",
    },
    {
      icon: <FiUsers />,
      title: "VLSI Testing",
      description:
        " It emphasizes the importance of functional verification and testing in the design and manufacturing process. ",
    },
  ];

const SlideList = [
    
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--22',
        category: '',
        title: 'VLSI Training',
        description: "Welcome to our comprehensive and hands-on VLSI design verification course, where you'll gain the knowledge and skills needed to excel in this rapidly evolving field.",
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--20',
        category: '',
        title: 'VLSI Design',
        description: "Welcome to our comprehensive and hands-on VLSI design verification course, where you'll gain the knowledge and skills needed to excel in this rapidly evolving field.",
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-30%',
      width: '600px',
      transform: 'translate(-50%, -50%)',
      background: 'linear-gradient(0deg, rgb(0 0 0 / 55%), rgb(0 0 0 / 57%)), url(https://static.javatpoint.com/fullformpages/images/vlsi-full-form.png)'

    },
  };
  
function DigitalAgency() {

        let title = 'About',
        description = 'IPCS Global Bangalore is know for training in Embedded sector covering Automotive, IoT, Medical and aerospace. Our instructors are passionate about        sharing their knowledge and will guide you through every step of your learning journey.';
       
        let subtitle;
        const [modalIsOpen, setIsOpen] = React.useState(true);
      
        function openModal() {
          setIsOpen(true);
        }
      
        function afterOpenModal() {
          subtitle.style.color = '#f00';
        }
      
        function closeModal() {
          setIsOpen(false);
        }
 
        return(
            <Fragment> 
                
                <Helmet pageTitle="Best VLSI Institute in Bangalore" />

                {/* Start Header Area  */}
                <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />                {/* End Header Area  */}
                
                <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ContactFour />
      </Modal>

                {/* Start Slider Area   */}
                <div className="slider-wrapper color-white">
                    <div className="slider-activation slider-digital-agency">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a className="text-center" href="/contact">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
                {/* End Service Area  */} 

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_image bg_image--3">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--15">
                                        <h2 className="title">Training Process</h2>
                                        <p>Unlock the World of VLSI RTL Design & Verification</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/contact"><span>Contact Us</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start About Area */}
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabOne tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Testimonial Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
         <h2 className="title" style={{textAlign: "center"}}>Corporate Partner</h2>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Testimonial Area */}

                
                {/* Start call To Action  */}
                <div className="rn-contact-page bg_color--1">
          <ContactTwo />
        </div>                {/* End call To Action  */}

                {/* Start Footer Style  */}
        <Footer />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }


export default DigitalAgency;