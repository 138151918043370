import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-3',
        category: 'Introduction',
        title: 'Basics of the course'
    },
    {
        image: 'image-2',
        category: 'Digital Design',
        title: 'Digital Logic design, Combinational logic design, Sequential logic Design, Counters, Registers, FSM, FIFO'
    },
    {
        image: 'image-3',
        category: 'System Verilog',
        title: 'Data types, Tasks and Functions, Classes Randomization & Constraints Assertion, Coverage'
    },
    {
        image: 'image-4',
        category: 'Digital Verilog',
        title: 'Design Methodologies, Verilog Modelling Styles, System Tasks, Logic gates using Muxx, Encoder, Decoder' 
    },
    {
        image: 'image-2',
        category: 'Universal Verification Methodology',
        title: 'Introduction, UVM Testbench Architecture, Phases, TLM, Reporting, Configurations, Factory & UVM Projects'
    },
    {
        image: 'image-4',
        category: 'Projects',
        title: 'SPI, UART, 12C, Memory Controller,Linux Operation System'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href="/contact">{value.title}</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;