import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      IPCS Global, Bangalore offers an excellent platform for
                      entering into the VLSI sector. It allows me to learn more
                      about design concepts. The project experience is quite
                      beneficial in understanding tool-related concepts, and it
                      helps in working as a Design Engineer. Institute supports
                      to begin a career in the VLSI INDUSTRY.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Aklima </span>
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      I finished IPCS Global Bangalore's industrial-focused
                      physical design program. I was able to refresh my
                      knowledge of fundamentals and digital circuits at this
                      excellent training facility. An ideal place to begin
                      learning VLSI and begin a career in this field.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Fatima Asrafy </span>
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      I highly recommend IPCS Global for their industry-focused
                      training. The institute's collaboration with leading
                      semiconductor companies ensures that their courses are
                      up-to-date and aligned with industry standards. The
                      faculty members are experienced professionals who are
                      passionate about teaching and guiding students towards
                      success.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Jannat Tumpa </span>
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      IPCS Global's placement assistance is commendable. Through
                      their guidance and support, I secured a job in a reputed
                      VLSI company even before completing the training. The
                      institute's connections with the industry played a crucial
                      role in landing the opportunity.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Johns Due </span>
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      IPCS Global's commitment to continuing education and
                      professional development is impressive. The workshops and
                      webinars organized by the institute exposed me to the
                      latest advancements in VLSI technology and helped me stay
                      updated with industry trends. Their certification program
                      added value to my profile and boosted my career prospects.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Mirin Doe </span>
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      IPCS Global, Bangalore has been a game-changer in my
                      career as an aspiring VLSI professional. Their
                      comprehensive training programs provided me with a solid
                      foundation in VLSI design and verification. The hands-on
                      experience in their well-equipped labs helped me gain
                      practical skills and confidence.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Amar Orthi </span>
                    </h6>
                  </div>
                </div>
              </TabPanel>
              

              <TabList className="testimonial-thumb-wrapper">
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-1.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-2.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-3.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-4.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-5.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-6.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
