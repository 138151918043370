// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import Demo from './page-demo/Demo';
import MainDemo from './home/MainDemo';
import Startup from './home/Startup';
import Paralax from './home/Paralax1';
import HomePortfolio from './home/HomePortfolio';
import DigitalAgency from './home/DigitalAgency';
import CreativeAgency from './home/CreativeAgency';
import PersonalPortfolio from './home/PersonalPortfolio';
import Business from './home/Business';
import StudioAgency from './home/StudioAgency';
import PortfolioLanding from './home/PortfolioLanding';
import CreativeLanding from './home/CreativeLanding';
import HomeParticles from './home/HomeParticles';
import CreativePortfolio from './home/CreativePortfolio';
import DesignerPortfolio from './home/DesignerPortfolio';
import InteriorLanding from './home/Interior';
import CorporateBusiness from './home/CorporateBusiness';
import InteractiveAgency from './home/InteractiveAgency';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";


// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Paralax1 from './home/Paralax1';
import Paralax2 from './home/Paralax2';
import Paralax3 from './home/Paralax3';
import Paralax4 from './home/Paralax4';
import Paralax5 from './home/Paralax5';
import Paralax6 from './home/Paralax6';
import Register from './elements/Register';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'AW-11107336120'
  }
  TagManager.initialize(tagManagerArgs)

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`/`} component={DigitalAgency}/>

                        {/* Element Layot */}
                        <Route exact path={`/service`} component={Service}/>
                        <Route exact path={`/best-vlsi-training-bangalore`} component={Paralax1}/>
                        <Route exact path={`/best-vlsi-training-hyderabad`} component={Paralax2}/>
                        <Route exact path={`/best-vlsi-training-odisha`} component={Paralax3}/>
                        <Route exact path={`/best-vlsi-training-chennai`} component={Paralax4}/>
                        <Route exact path={`/best-vlsi-training-delhi`} component={Paralax5}/>
                        <Route exact path={`/best-vlsi-training-gujarat`} component={Paralax6}/>
                        <Route exact path={`/contact`} component={Contact}/>
                        <Route exact path={`/about`} component={About}/>
                        <Route exact path={`/blog`} component={Blog}/>
                        <Route exact path={`/blog-details`} component={BlogDetails}/>
                        <Route exact path={`/landing-page`} component={CorporateBusiness}/>
                        <Route exact path={`/java-course`} component={HomeParticles}/>
                        <Route exact path={`/register`} component={Register}/>


                        
                        <Route path={`/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();