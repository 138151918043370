import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/intel.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/info.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/cap.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/infi.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/junt.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/ust.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/p2f.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/syn.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/hcl.png" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;