import React, { Component, Fragment } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Accordion01 from "../elements/Accordion";
import Helmet from "../component/common/Helmet";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiChevronUp,
  FiCheck,
} from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";
import BrandTwo from "../elements/BrandTwo";
import { ProgressBar } from "react-bootstrap";

const SlideList = [
  {
    textPosition: "text-right",
    bgImage: "bg_image--36",
    category: "",
    title: "Best VLSI Trainers",
    description:
      "Top VLSI institute, 100% job placements, industry standard training by expert engineers",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
  // {
  //   textPosition: "text-left",
  //   bgImage: "bg_image--31",
  //   category: "",
  //   title: "Best VLSI Trainers",
  //   description:
  //     "Top VLSI institute, 100% job placements, industry standard training by expert engineers",
  //   buttonText: "Contact Us",
  //   buttonLink: "/contact",
  // },
];

const ServiceListOne = [
  {
    icon: <FiCast />,
    title: "VLSI Design",
    description:
      "VLSI Training programs focus on teaching the principles and techniques of digital IC design. ",
  },
  {
    icon: <FiLayers />,
    title: "VLSI Training",
    description:
      "VLSI training covers the fundamentals of IC designs & verification techniques.",
  },
  {
    icon: <FiUsers />,
    title: "VLSI Testing",
    description:
      " It emphasizes the importance of functional verification and testing in the design and manufacturing process. ",
  },
];

const starndardService = [
  {
    image: "01",
    title: "Hands-on experience",
    description:
      "This programs includes practical exercises & internships that allow participants to work on real-world design & verification challenges.",
  },
  {
    image: "02",
    title: "Industry relevance",
    description:
      "VLSI training programs are usually designed to meet the requirements and standards of the semiconductor industry.",
  },
  {
    image: "03",
    title: "Networking opportunities",
    description:
      "VLSI training often provides opportunities for participants to interact with industry professionals, mentors, and fellow students.",
  },
];

class CorporateBusiness extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      "The Philosophy Of business analytics",
      "Fast-Track Your business",
      "Lies And Damn Lies About business",
      "The Ultimate Deal On business",
    ];
    var namesItemTwo = [
      "Proof That business Really Works",
      "Here Is What You Should Do For Your business",
      "The Hidden Mystery Behind business",
    ];

    const PostList = BlogContent.slice(0, 3);

    return (
      <Fragment>
        <Helmet pageTitle="Home - IPCS" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area */}
        <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a className="text-center" href="/contact">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

                {/* Start Counterup Area */}
                <div
          className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white"
          data-black-overlay="7"
        >
          <div className="container">
            <CounterOne />
          </div>
        </div>
        {/* End Counterup Area */}

        <br />
        <br />
        <br />
        <br />


         {/* Start About Area  */}
         <div className="rn-about-area bg_color--1">
                <div className="rn-about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    {/* <div className="section-title">
                                        <h2 className="title">What do we do?</h2>
                                        <p className="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.</p>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Why Choose Iform?
</h3>
                                                <div className="rn-progress-bar progress-bar--3">
                                                    <div className="single-progress custom-color--1">
                                                        <h6 className="title">Expert Trainers</h6>
                                                        <ProgressBar now={100} />
                                                        <span className="label">100%</span>
                                                    </div>

                                                    <div className="single-progress custom-color--2">
                                                        <h6 className="title">Industry Relevant Courses
</h6>
                                                        <ProgressBar now={100} />
                                                        <span className="label">100%</span>
                                                    </div>

                                                    <div className="single-progress custom-color--3">
                                                        <h6 className="title">Placement Assistance
</h6>
                                                        <ProgressBar now={100} />
                                                        <span className="label">100%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5 mt_md--40 mt_sm--40">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area  */}

<br/>
<br/>
<br/>
<br/>



        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-4.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Working Process</h2>
                    <p>FAQs</p>
                  </div>
                  <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div>
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      See how it works
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start About Area  */}
        <div className="rn-about-area  bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">About</h2>
                    <p className="description">
                      IPCS Global is the leading name in VLSI training in India.
                      Our academy holds the record and goodwill of having
                      trained thousands of students and they successfully
                      started their dream careers in VLSI.
                      <br />
                      <br />
                      IPCS Global is the best choice of institute to learn VLSI.
                      With a world-class technical training system and
                      futuristic learning, IPCS Global offers the best in the
                      industry. The academy provides career-oriented coaching to
                      students, along with 100% placement assistance.
                    </p>
                  </div>
                  {/* <div className="mt--30">
                    <h4>Lorem ipsum dolor sit.</h4>
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-3.png"
                    alt="About Images"
                  />
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="UiIYH8MO8-w"
                    onClose={() => this.setState({ isOpen: false })}
                  />
                  <button
                    className="video-popup position-top-center theme-color"
                    onClick={this.openModal}
                  >
                    <span className="play-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
        {/* End About Area  */}

        {/* Start Blog Area */}
        {/* <div className="rn-blog-area pt--120 pb--80 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center">
                  <h2 className="title">Latest Blogs</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--60">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href="/blog-details">
                        <img
                          className="w-100"
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">
                        <a href="/blog-details">{value.title}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href="/blog-details">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* End Blog Area */}

         {/* Start Brand Area */}
         <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
         <h2 className="title" style={{textAlign: "center"}}>Corporate Partner</h2>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default CorporateBusiness;
