import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";

class BlogDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="VLSI Training through IPCS Global, Bangalore" />
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    VLSI training through <br /> IPCS Global
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      June 01, 2023
                    </li>
                    {/* <li><FiUser />NipaBali</li>
                                        <li><FiMessageCircle />15 Comments</li>
                                        <li><FiHeart />Like</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--40 pb--40 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>
                      VLSI stands for Very Large Scale Integration and refers to
                      the process of designing and fabricating integrated
                      circuits (ICs) that contain a large number of transistors
                      on a single chip. VLSI technology is crucial in the
                      development of modern electronic devices, ranging from
                      smartphones and computers to automotive electronics and
                      medical equipment.
                    </p>
                    <p>
                      VLSI training programs aim to equip individuals with the
                      knowledge and skills required to design, analyze, and test
                      integrated circuits. These training programs cover various
                      aspects of VLSI design, including digital and analog
                      circuit design, semiconductor technology, system
                      architecture, verification methodologies, and fabrication
                      techniques.
                    </p>
                    <p>
                      IPCS (Industrial Project and Consultancy Services) is an
                      organization that offers VLSI training and aims to help
                      individuals excel in the field of VLSI. IPCS provides
                      comprehensive training programs that cover both
                      theoretical concepts and practical implementation of VLSI
                      design. Here's how IPCS can assist you in excelling in
                      VLSI:
                    </p>
                    <ul>
                      <li>
                        <b>Experienced Faculty :</b> IPCS has a team of
                        experienced faculty members who have expertise in VLSI
                        design and related domains. They provide high-quality
                        training, guidance, and mentorship throughout the
                        program.
                      </li>
                      <li>
                        <b>Industry-Relevant Curriculum :</b> IPCS designs its
                        training programs to align with industry requirements.
                        The curriculum includes the latest trends, tools, and
                        techniques used in the VLSI industry, ensuring that
                        participants gain relevant skills and knowledge.
                      </li>
                      <li>
                        <b>Hands-on Practical Training :</b> IPCS emphasizes
                        hands-on practical training to enhance participants'
                        understanding of VLSI design. They provide access to
                        industry-standard tools and software for simulation,
                        synthesis, and layout design, allowing participants to
                        gain practical experience.
                      </li>
                      <li>
                        <b>Project-Based Learning :</b> IPCS incorporates
                        project-based learning into their training programs.
                        Participants work on real-world projects, which enables
                        them to apply their theoretical knowledge to practical
                        design challenges. This experience enhances their
                        problem-solving and project management skills.
                      </li>
                      <li>
                        <b>Placement Support :</b> IPCS provides placement
                        assistance to participants by collaborating with
                        companies in the VLSI industry. They help participants
                        with resume building, interview preparation, and
                        connecting with potential employers.
                      </li>
                    </ul>
                    <br />
                    <p>
                      By enrolling in IPCS's VLSI training program, individuals
                      can acquire the necessary skills and knowledge to excel in
                      the field of VLSI design. It offers a structured learning
                      environment, expert guidance, practical exposure, and
                      opportunities for industry interaction, which are vital
                      for a successful career in VLSI.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default BlogDetails;
