import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiCheck } from "react-icons/fi";

class TabsOne extends Component{
    render(){
        let 
        tab1 = "Job Profiles", 
        tab2 = "Companies",
        tab3 = "Industries";
        const { tabStyle } = this.props;
        var namesItemOne = [
            'RTL Design engineer',
            'RTL Verification Engineer',
            'R&D Engineer',
            'Verification & Validation Engineer',
            'Application Engineer',
            'SOC Verification Engineer',
            'DFX Engineer',
            'Formal Verification Engineer'
        ];
        var namesItemTwo = [
            'Tech Mahindra',
            'Intel',
            'Qualcomm',
            'IBM',
            'Innominds',
            'Alten',
            'Smartsoc',
            'NXP'
        ];
        var namesItemThree = [
            'Aerospace',
            'Automotive',
            'Chip design',
            'IOT',
            'Robotics',
            'Medical Instrumentation'
        ];
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <div className="mt--30">
                                               <ul className="list-style--1">
                                                   {namesItemOne.map((name, index) => {
                                                       return <li key={ index }><FiCheck /> {name}</li>;
                                                   })}
                                               </ul>
                                           </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <div className="mt--30">
                                               <ul className="list-style--1">
                                                   {namesItemTwo.map((name, index) => {
                                                       return <li key={ index }><FiCheck /> {name}</li>;
                                                   })}
                                               </ul>
                                           </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <div className="mt--30">
                                               <ul className="list-style--1">
                                                   {namesItemThree.map((name, index) => {
                                                       return <li key={ index }><FiCheck /> {name}</li>;
                                                   })}
                                               </ul>
                                           </div>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsOne;