import React, { Component , Fragment } from "react";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import { slickDot } from "../page-demo/script";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import { FiChevronUp , FiX , FiMenu, FiArchive, FiLayers, FiUsers, FiMonitor, FiCast } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
  } from "react-accessible-accordion";
  // Demo styles, see 'Styles' section below for some notes on use.
  import "react-accessible-accordion/dist/fancy-example.css";
const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'JAVA TRAINING ',
        description: 'Best Java Training course with placement guaranteed in Bangalore. Learn Java programming from basics to advanced and get real time experience. ',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

const ServiceList = [
    {
        icon: <FiArchive />,
        title: 'Convenient learning format',
        description: 'Online learning format with mentorship from industry experts        '
    },
    
    {
        icon: <FiLayers />,
        title: 'Dedicated career services',
        description: 'Resume & interview preps with industry experts & exclusive job board'
    },
    {
        icon: <FiUsers />,
        title: 'Learn from the best',
        description: 'Award winning faculties in Java domain from top IT background.'
    },
    {
        icon: <FiMonitor />,
        title: 'Structured program with dedicated support',
        description: 'Dedicated program manager to ensure that students make progress and have learning outcomes'
    },
    {
        icon: <FiCast />,
        title: 'Hands on learning',
        description: 'Become job-ready by applying what you learn and build real-life projects.'
    },
    
]

class HomeParticles extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        return(
            <Fragment>
                <Helmet pageTitle="Home Particles" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/iform.png" width={80} alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/iform.png" width={80} alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home', 'about', 'highlight', 'portfolio','testimonial', 'contact']} currentClassName="is-current" offset={-200}>
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#about">About</a></li>
                                    <li><a href="#highlight">Highlights</a></li>
                                    <li><a href="#portfolio">FAQ</a></li>
                                    <li><a href="#testimonial">Testimonial</a></li>
                                    <li><a href="#contact">Contact</a></li>
                                </Scrollspy>
                            </nav>
                            <div className="header-btn">
                                <a className="rn-btn" href="tel:+918310902971​">
                                    <span>Call US​</span>
                                </a>
                            </div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                
                <div className="slider-activation slider-creative-agency with-particles" id="home">
                    <div className="frame-layout__particles">
                        <Particles className="particle"  
                            options={{
                                style:{
                                    position: "absolute"
                                },
                                fpsLimit: 100,
                                interactivity: {
                                detectsOn: "canvas",
                                events: {
                                    onClick: {
                                    enable: true,
                                    mode: "push",
                                    },
                                    onHover: {
                                    enable: true,
                                    mode: "repulse",
                                    },
                                    resize: true,
                                },
                                modes: {
                                    bubble: {
                                        distance: 100,
                                        duration: 2,
                                        opacity: 0.8,
                                        size: 10,
                                        color: "#888",
                                    },
                                    push: {
                                    quantity: 4,
                                    },
                                    repulse: {
                                        distance: 100,
                                        duration: 0.4,
                                        color: "#888",
                                    },
                                },
                                },
                                particles: {
                                color: {
                                    value: "#888",
                                },
                                links: {
                                    color: "#888",
                                    distance: 150,
                                    enable: true,
                                    opacity: 0.5,
                                    width: 1,
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outMode: "bounce",
                                    random: false,
                                    speed: 6,
                                    straight: false,
                                   
                                },
                                number: {
                                    density: {
                                        enable: true,
                                        value_area: 2000,
                                    },
                                    value: 80,
                                },
                                opacity: {
                                    value: 0.5,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    random: true,
                                    value: 5,
                                },
                                },
                                detectRetina: true,
                            }}
                        />
                    </div>
                    <div className="bg_image bg_image--27">
                            {SlideList.map((value , index) => (
                                <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                {/* <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="service">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="section-title text-left mb--30">
                                    <h2>Course Highlight</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>   */}
                {/* End Service Area  */} 

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--5" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">About Us</h2>
                                            <p className="description">IPCS Global is the leading name in VLSI training in India. Our academy holds the record and goodwill of having trained thousands of students and they successfully started their dream careers in VLSI. Our placement cell provides students with guidance and assistance in achieving their goal. We provide training projects related to latest trends in industry. Every student gets a project.
</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>IPCS Global, Bangalore is the best choice of institute to learn VLSI. With a world-class technical training system and futuristic learning, IPCS Global offers the best in the industry.

</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>IPCS Global, Bangalore has been providing quality education for over two decades now and is highly trusted by students and organizations alike.

</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                <div className="service-area bg_color--5" id="highlight">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h3>Course Highlight</h3>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/contact">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--140 p pb--110 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Team Area  */}
                <div className="rn-about-area ptb--120 bg_color--5" id="portfolio">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-4.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">FAQ</h2>
                  </div>
                  <div className="accordion-wrapper mt--30">
                  <Accordion className="accodion-style--1" preExpanded={"0"}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Why should I do Java programming?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
          With the advancement of mobile application development, Java has become an essential programming language to be added to any software professional's skill set. It is one of the best choices of developers in the world of programming. Currently, Java finds its application in mobile and web development. Additionally, Hadoop is the most widely used framework of Big Data architecture, and it uses Java for design & development. Hence, Java makes way for professionals in the field of Big Data.
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
             Does Java come under coding?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
          Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies
as possible. Java applications are typically compiled to bytecode that can run on any Java virtual machine (JVM) regardless of the underlying computer architecture.
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
            What are the job options after successful completion of the Java Course?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul>
              <li>Junior Developer</li>
              <li>Software Developer</li>
              <li>Senior Developer</li>
              <li>Software Engineer</li>
              <li>Java Web Developer</li>
              <li>Java Developer</li>
              <li>Java Android Developer</li>
              <li>Java Application Developer</li>
              <li>Database Administrator</li>
              <li>Senior Computer Programmer</li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
            How long is the certificate valid in India?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
          We provide you certificates that are valid for a lifetime! You will receive a Certificate of Completion that is recognized in India, demonstrating your commitment to learning new skills.
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
            Does Java come under coding?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
          Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible. Java applications are typically compiled to bytecode that can run on any Java virtual machine (JVM) regardless of the underlying computer architecture.
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>                  </div>
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      See how it works
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                {/* End Team Area  */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120" id="testimonial">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}
                


                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <Contact />
                </div>
                {/* End Contact Us */}


                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}
export default HomeParticles;