import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp, FiArchive } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import { FaIdeal } from "react-icons/fa";


const ServiceList = [
    {
        icon: <FiArchive />,
        title: 'Workshops & Seminars',
        description: 'IForm Training Institute organizes workshops and webinars conducted by industry experts to keep participants updated with emerging technologies, design methodologies, and industry trends.'
    },
    
    {
        icon: <FiLayers />,
        title: 'Customized Training',
        description: 'These programs can be designed for different skill levels, from beginner to advanced, and can focus on specific domains such as digital design, verification, or physical design.'
    },
    {
        icon: <FiUsers />,
        title: 'Industry Partnerships',
        description: 'IForm Training often collaborate with semiconductor companies and industry professionals to stay updated with the latest trends and industry practices.'
    },
    {
        icon: <FiMonitor />,
        title: 'Placement Support',
        description: 'Institutes may provide placement assistance to their participants, connecting them with potential job opportunities in the VLSI industry. '
    },
    {
        icon: <FiCast />,
        title: 'Comprehensive Courses',
        description: 'IForm Training Institutes offer structured courses covering various aspects of VLSI design, semiconductor technology, and verification methodologies.'
    },
    {
        icon: <FaIdeal />,
        title: 'Certification Programs',
        description: 'Institute also offer certification programs that validate participants skills and knowledge in VLSI design and technology.'
    },
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Service - IPCS' />
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Services'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>IForm Chip Solutions</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/contact">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;