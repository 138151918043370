import React, { Component, Fragment } from "react";
import { Parallax } from "react-parallax";
import ScrollToTop from "react-scroll-up";
import { Link } from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import ServiceList from "../elements/service/ServiceList";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import ContactTwo from "../elements/contact/ContactTwo";

// const SlideList = [
//   {
//     textPosition: "text-center",
//     category: "",
//     title: "Marketing",
//     description:
//       "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.",
//     buttonText: "Contact Us",
//     buttonLink: "/contact",
//   },
// ];

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'IForm Chip Solutions',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]


const sliderImage = "/assets/images/bg/bg-image-11.jpg";

const image1 = "/assets/images/bg/bg-image-7.jpg";

const image2 = "/assets/images/bg/bg-image-36.jpg";

const image3 = "/assets/images/bg/bg-image-66.jpg";

const image6 = "/assets/images/bg/paralax/bg-image-6.jpg";

const image7 = "/assets/images/bg/paralax/bg-image-7.jpg";

const image8 = "/assets/images/bg/paralax/bg-image-8.jpg";

const PortfolioList = [
  {
    image: "image-2",
    category: "IForm Training Institute organizes workshops and webinars conducted by industry experts to keep participants updated with emerging technologies, design methodologies, and industry trends.D",
    title: "Workshops & Seminars",
  },
  {
    image: "image-2",
    category: "These programs can be designed for different skill levels, from beginner to advanced, and can focus on specific domains such as digital design, verification, or physical design.",
    title: "Customized Training",
  },
  {
    image: "image-2",
    category: "IForm Training often collaborate with semiconductor companies and industry professionals to stay updated with the latest trends and industry practices.",
    title: "Industry Partnerships",
  },
  {
    image: "image-2",
    category: "Institutes may provide placement assistance to their participants, connecting them with potential job opportunities in the VLSI industry.",
    title: "Placement Support",
  },
  {
    image: "image-2",
    category: "IForm Training Institutes offer structured courses covering various aspects of VLSI design, semiconductor technology, and verification methodologies.",
    title: "Comprehensive Courses",
  },
  {
    image: "image-2",
    category: "Institute also offer certification programs that validate participants skills and knowledge in VLSI design and technology.",
    title: "Certification Programs",
  },
];

class Paralax2 extends Component {
  render() {
    const PostList = BlogContent.slice(0, 3);
    // const ServiceContent = ServiceList.slice(0 , 3);
    return (
      <Fragment>
        <Helmet pageTitle="Top VLSI Institue in Hyderabad" />

        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        
        <div className="slider-wrapper">
                {/* Start Single Slide */}
                {SlideList.map((value , index) => (
                    <div className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--5 rn-slider-height" key={index}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="designer-thumbnail">
                                        <img src="/assets/images/bg/bg-image-32.jpg" alt="Slider Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className={`inner ${value.textPosition}`}>
                                        {value.category ? <span>{value.category}</span> : ''}
                                        <h1 className="title"> Best Institute for <br/>
                                        <TextLoop>
                                            <span> VLSI Design.</span>
                                            <span> VLSI Training.</span>
                                            <span> VLSI Testing.</span>
                                        </TextLoop>{" "}
                                        </h1>
                                        <h2 style={{marginTop: '-12px'}}>courses in Hyderabad.</h2>
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                        {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {/* End Single Slide */}
            </div>

        {/* Start Service Area  */}
        <Parallax
          className="rn-paralax-service"
          bgImage={image1}
          strength={1000}
        >
          <div className="service-area ptb--120">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                    <h2>Our Service</h2>
                  </div>
                </div>
              </div>
              <ServiceList
                item="6"
                column="col-lg-4 col-md-6 col-sm-6 col-12"
              />
            </div>
          </div>
        </Parallax>
        {/* End Service Area  */}

        {/* Start Service Area  */}
        <Parallax
          className="rn-paralax-portfolio"
          bgImage={image2}
          strength={500}
        >
          <div className="portfolio-area ptb--120" data-black-overlay="6">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                    <h2>Our Benefits</h2>
                    {/* <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration.
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Start Single Portfolio  */}
                {PortfolioList.map((value, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-6 col-12 mt--40"
                    key={index}
                  >
                    <div className="portfolio">
                      <div className="thumbnail-inner">
                        <div className={`thumbnail ${value.image}`}></div>
                        <div className={`bg-blr-image ${value.image}`}></div>
                      </div>
                      <div className="content">
                        <div className="inner">
                          <h4>
                            <a href="/contact">{value.title}</a>
                          </h4>
                          <br />
                          <p>{value.category}</p>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* End Single Portfolio  */}
              </div>
            </div>
          </div>
        </Parallax>
        {/* End Service Area  */}

        {/* Start CounterUp Area */}
        <Parallax
          className="rn-counterup-area rn-paralax-counterup"
          bgImage={image6}
          strength={1000}
        >
          <div className="counterup-area ptb--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                    <h3>Our Fun Facts</h3>
                  </div>
                </div>
              </div>
              <CounterOne />
            </div>
          </div>
        </Parallax>
        {/* End CounterUp Area */}

        {/* Start Testimonial Area */}
        <Parallax
          className="rn-testimonial-area rn-paralax-testimonial  rn-testimonial-light"
          bgImage={image7}
          strength={500}
          data-black-overlay="5"
        >
          <div className="testimonial-area ptb--120" data-black-overlay="6">
            <div className="container">
              <Testimonial />
            </div>
          </div>
        </Parallax>
        {/* End Testimonial Area */}

       

        {/* Start Brand Area */}
        <Parallax
          className="rn-brand-area brand-separation ptb--120"
          bgImage={image3}
          strength={1000}
        >
          <div className="brand-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <BrandTwo />
                </div>
              </div>
            </div>
          </div>
        </Parallax>
        {/* End Brand Area */}

          {/* Start Contact Page Area  */}
          <div className="rn-contact-page bg_color--1">
          <ContactTwo />
        </div>
        <br/>
        <br/>
        <br/>
        {/* End Contact Page Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </Fragment>
    );
  }
}
export default Paralax2;
